import React, { useState, useContext, useEffect, useCallback } from 'react'
import find from 'lodash/find'
import isEqual from 'lodash/isEqual'
import PropTypes from 'prop-types'
import StoreContext from '~/context/StoreContext'
import {
  BrowserView,
  MobileView,
  isSafari,
  isMobileSafari,
  isTablet,
} from 'react-device-detect'
import styled from '@emotion/styled'

import Up from '../../images/up-arrow.png'
import Down from '../../images/down-arrow.png'

export const ButtonCard = styled.button`
  font-family: LTUnivers-BasicRegular;
  padding: 11px 0 10px 5px;
  font-size: 11px;
  background-color: white;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  width: 100%;
  margin: -30px 0 0 0;
  cursor: pointer;
  line-height: 21px;
  &:hover {
    background: black;
    color: white;
  }
`
export const ButtonCardAdded = styled.button`
  font-family: LTUnivers-BasicRegular;
  padding: 11px 0 10px 5px;
  font-size: 11px;
  color: white;
  line-height: 21px;
  background-color: black;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  width: 100%;
  margin: -30px 0 0 0;
  cursor: pointer;
`

export const ButtonCardMobile = styled.div`
  font-family: LTUnivers-BasicRegular;
  background-color: white;
  border: 1px solid black;
  color: black;
  line-height: 42px;
  display: flex;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  font-size: 12px;
  text-align: center;
  margin: -30px 0 0 0;
`

export const ButtonCardTablet = styled.div`
  font-family: LTUnivers-BasicRegular;
  padding: 8px 30px 5px 30px;
  margin-left: -12px;
  background-color: white;
  width: max-content;
  cursor: pointer;
  border: 1px solid darkgrey;
  font-size: 12px;
`

export const ColorsDescription = styled.div`
  display: flex;
  justify-content: flex-start;
`
export const SizesSelection = styled.div`
  font-family: 'LTUnivers-BasicRegular' !important;
  font-size: 11px;
  background: black;
  color: white;
  display: flex;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0px 0 0 0;
  border: 1px solid black;
  @media screen and (max-width: 767px) {
    input,
    select,
    textarea {
      font-size: 16px;
    }
  }
`

export const SizesSelectionSelect = styled.select`
  font-family: 'LTUnivers-BasicRegular' !important;
  font-size: 11px;
  background: black;
  color: white;
  display: flex;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-border-radius: 0;  /* Safari 3-4, iOS 1-3.2, Android 1.6- */    
  -moz-border-radius: 0;  /* Firefox 1-3.6 */     
  border-radius: 0;  /* Opera 10.5, IE 9, Safari 5, Chrome, Firefox 4, iOS 4, Android 2.1+ */
  margin: -30px 0 0 0;
  padding: 12px 12px 12px 12px;
  height: 42px;
  border: 1px solid black;
  border-radius: none';
  @media screen and (max-width: 767px) {
    input,
    select,
    textarea {
      font-size: 16px;
    }
  }
`

export const SizeItems = styled.option`
  background: #cccccc;
  padding: 0 0 0 5px;
  margin: 2px 0 0 0;
  cursor: pointer;
  border: 1px solid black;
  &:hover {
    background: black;
    color: white;
  }
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  @media screen and (max-width: 767px) {
    input,
    select,
    textarea {
      font-size: 16px;
    }
  }
`

export const SizeItem = styled.p`
  padding: 0px 0 0px 9px;
`

export const ArrowContainer = styled.div`
  padding: 0px 12px 0px 9px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: right;
  align-items: flex-end;
  position: relative;
  top: 6px;
}
`

const ProductForm = ({ product }) => {
  const {
    options,
    variants,
    variants: [initialVariant],
    priceRange: { minVariantPrice },
  } = product
  const [variant, setVariant] = useState({ ...initialVariant })
  const [quantity, setQuantity] = useState(1)
  const {
    addVariantToCart,
    store: { client, adding },
  } = useContext(StoreContext)

  const [selectedColor, setSelectedColor] = useState(options[0].values[0])
  const [showSizes, setShowSizes] = useState(false)
  const [sizeValue, setSizeValue] = useState('Select Size')
  const [colorButton, setColorButton] = useState(false)
  const [cartMessage, setCartMessage] = useState('Add to Cart')
  const productVariant =
    client.product.helpers.variantForOptions(product, variant) || variant
  const [available, setAvailable] = useState(productVariant.availableForSale)

  const checkAvailability = useCallback(
    productId => {
      client.product.fetch(productId).then(fetchedProduct => {
        // this checks the currently selected variant for availability
        const result = fetchedProduct.variants.filter(
          variant => variant.id === productVariant.shopifyId
        )
        if (result.length > 0) {
          setAvailable(result[0].available)
        }
      })
    },
    [client.product, productVariant.shopifyId, variants]
  )

  useEffect(() => {
    checkAvailability(product.shopifyId)
  }, [productVariant, checkAvailability, product.shopifyId])

  const handleQuantityChange = ({ target }) => {
    setQuantity(target.value)
  }

  const handleOptionChange = (optionIndex, event) => {
    if (event.target.name === 'Size') {
      giveSizeValue(event.target.value)
    }
    const { value } = event.target
    const currentOptions = [...variant.selectedOptions]

    currentOptions[optionIndex] = {
      ...currentOptions[optionIndex],
      value,
    }

    const selectedVariant = find(variants, ({ selectedOptions }) =>
      isEqual(currentOptions, selectedOptions)
    )

    setVariant({ ...selectedVariant })
  }

  const handleAddToCart = () => {
    addVariantToCart(productVariant.shopifyId, quantity)
    setColorButton(true)
  }

  useEffect(() => {}, [adding])

  const checkDisabled = (name, value) => {
    const match = find(variants, {
      selectedOptions: [
        {
          name: name,
          value: value,
        },
      ],
    })

    if (match === undefined) return true
    if (match.availableForSale === true) return false
    return true
  }

  const price = Intl.NumberFormat(undefined, {
    currency: minVariantPrice.currencyCode,
    minimumFractionDigits: 2,
    style: 'currency',
  }).format(variant.price)

  const giveSizeValue = value => {
    setSizeValue(value)
    setShowSizes(false)
  }

  useEffect(() => {
    setShowSizes(showSizes)
  }, [showSizes])

  return (
    <>
      <BrowserView>
        {options.map(({ id, name, values }, index) => {
          if (name === 'Color') {
            return (
              <React.Fragment key={id}>
                {' '}
                <div
                  value
                  name={name}
                  key={id}
                  onClick={event => handleOptionChange(index, event)}
                >
                  {values.map(value => (
                    <button
                      value={value}
                      key={`${name}-${value}`}
                      onClick={() => setSelectedColor(value)}
                      style={{
                        background: '#' + value,
                        borderRadius: '50%',
                        width: selectedColor === value ? '12px' : '12px',
                        height: selectedColor === value ? '14px' : '14px',
                        margin: '0 20px 0 0',
                        border:
                          selectedColor === value
                            ? '2px solid black'
                            : '0.5px solid black',
                      }}
                      disabled={checkDisabled(name, value)}
                    ></button>
                  ))}
                </div>
                <br />
              </React.Fragment>
            )
          } else {
            return isSafari ? (
              <React.Fragment key={id}>
                <select
                  name={name}
                  key={id}
                  onChange={event => handleOptionChange(index, event)}
                  style={{ margin: '-0 0 50px 0' }}
                >
                  {values.map(value => (
                    <option
                      value={value}
                      key={`${name}-${value}`}
                      disabled={checkDisabled(name, value)}
                    >
                      {value}
                    </option>
                  ))}
                </select>
                <br />
              </React.Fragment>
            ) : (
              <React.Fragment key={id}>
                {' '}
                <SizesSelection onClick={() => setShowSizes(!showSizes)}>
                  <SizeItem onClick={() => setShowSizes(true)}>
                    {sizeValue}
                  </SizeItem>
                </SizesSelection>
                {showSizes ? (
                  <div
                    value
                    name={name}
                    key={id}
                    onClick={event => handleOptionChange(index, event)}
                  >
                    {values.map(value => {
                      return (
                        <SizeItems
                          value={value}
                          key={`${name}-${value}`}
                          onClick={() => giveSizeValue(value)}
                          disabled={checkDisabled(name, value)}
                        >
                          {value}
                        </SizeItems>
                      )
                    })}
                  </div>
                ) : null}
                <br />
              </React.Fragment>
            )
          }
        })}
        {/* <label htmlFor="quantity">Quantity </label>
        <input
          type="number"
          id="quantity"
          name="quantity"
          min="1"
          step="1"
          onChange={handleQuantityChange}
          value={quantity}
        /> */}

        <ButtonCard
          type="submit"
          disabled={!available || adding}
          onClick={handleAddToCart}
          style={{
            background: colorButton ? 'black' : null,
            color: colorButton ? 'white' : null,
          }}
        >
          Add to Cart
        </ButtonCard>

        {!available && sizeValue !== 'Select Size' && (
          <p>This Product is out of Stock!</p>
        )}
      </BrowserView>

      <MobileView>
        {options.map(({ id, name, values }, index) => {
          if (name === 'Color') {
            return (
              <React.Fragment key={id}>
                {' '}
                <div
                  value
                  name={name}
                  key={id}
                  onClick={event => handleOptionChange(index, event)}
                >
                  {values.map(value => {
                    return isMobileSafari ? (
                      <button
                        value={value}
                        key={`${name}-${value}`}
                        onClick={() => setSelectedColor(value)}
                        style={{
                          background: '#' + value,
                          borderRadius: '50%',
                          width: selectedColor === value ? '18px' : '18px',
                          height: selectedColor === value ? '22px' : '22px',
                          margin: '0 10px 0 0',
                          cursor: 'pointer',
                          border:
                            selectedColor === value
                              ? '2px solid black'
                              : '0.5px solid black',
                        }}
                        disabled={checkDisabled(name, value)}
                      ></button>
                    ) : (
                      <button
                        value={value}
                        key={`${name}-${value}`}
                        onClick={() => setSelectedColor(value)}
                        style={{
                          background: '#' + value,
                          borderRadius: '50%',
                          width: selectedColor === value ? '18px' : '18px',
                          height: selectedColor === value ? '18px' : '18px',
                          margin: '0 10px 0 0',
                          cursor: 'pointer',
                          border:
                            selectedColor === value
                              ? '2px solid black'
                              : '0.5px solid black',
                        }}
                        disabled={checkDisabled(name, value)}
                      ></button>
                    )
                  })}
                </div>
                <br />
              </React.Fragment>
            )
          } else {
            return (
              <React.Fragment key={id}>
                {' '}
                {!available && <p>This Product is out of Stock!</p>}
                {/* <ArrowContainer>
                  <img style={{ width: '15px', height: '15px' }} src={Up} />
                  <img style={{ width: '15px', height: '15px' }} src={Down} />
                </ArrowContainer> */}
                <SizesSelectionSelect
                  name={name}
                  key={id}
                  content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
                  onChange={event => handleOptionChange(index, event)}
                >
                  {values.map(value => (
                    <option
                      content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
                      value={value}
                      key={`${name}-${value}`}
                      disabled={checkDisabled(name, value)}
                    >
                      {value}
                    </option>
                  ))}
                </SizesSelectionSelect>
                {/* <SizeItem style={{ color: '#808080' }}>Size guide</SizeItem> */}
                <br />
              </React.Fragment>
            )
          }
        })}

        <ButtonCardMobile
          type="submit"
          disabled={!available || adding}
          onClick={handleAddToCart}
          style={{
            background: colorButton ? 'black' : null,
            color: colorButton ? 'white' : null,
          }}
        >
          Add to Cart
        </ButtonCardMobile>
      </MobileView>
    </>
  )
}

ProductForm.propTypes = {
  product: PropTypes.shape({
    descriptionHtml: PropTypes.string,
    handle: PropTypes.string,
    id: PropTypes.string,
    shopifyId: PropTypes.string,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        originalSrc: PropTypes.string,
      })
    ),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        values: PropTypes.arrayOf(PropTypes.string),
      })
    ),
    productType: PropTypes.string,
    title: PropTypes.string,
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        availableForSale: PropTypes.bool,
        id: PropTypes.string,
        price: PropTypes.string,
        title: PropTypes.string,
        shopifyId: PropTypes.string,
        selectedOptions: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.string,
          })
        ),
      })
    ),
  }),
  addVariantToCart: PropTypes.func,
}

export default ProductForm

import styled from '@emotion/styled'
import Image from 'gatsby-image'

export const OptionImages = styled.div`
  grid-column-start: 2;
  grid-column-end: 3;
  position: fixed;
  left: 15%;
`

export const ImgProductPage = styled(Image)`
  width: 100%;
  margin: 0 0 16px 0;
`

export const MaintImageContainer = styled.div`
  height: 97vh;
  overflow-y: auto;
  grid-column-start: 3;
  grid-column-end: 6;
  margin: -16px 0 0 0;
  -webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`

export const MainImageView = styled.div`
  z-index: -10;
`

export const DescriptionWrapper = styled.div`
  grid-column-start: 6;
  grid-column-end: 8;
  height: 90vh;
  overflow-y: hidden;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
`

export const ProductTitle = styled.div`
  margin-bottom: 15px;
  word-wrap: break-word;
  font-family: 'BDST';
  letter-spacing: 0em;
  font-size: 30px;
  margin: 0 0 0.5rem;
  line-height: 28px;
  color: black;
  width: 300px;
  align-self: start;
`

export const ProductTitlePrice = styled.div`
  font-size: 30px;
  margin-bottom: 15px;
  word-wrap: break-word;
  font-family: 'BDST';
  letter-spacing: 0em;
  margin: -20px 0 0.5rem;
  line-height: 4.5rem;
  color: black;
  width: 230px;
`

export const ProductDescription = styled.div`
  font-family: 'LTUnivers-BasicRegular';
  font-weight: 100;
  font-size: 12px;
  line-height: 23px;
`

export const ProductServices = styled.p`
  font-family: 'LTUnivers-BasicRegular';
  font-weight: 100;
  font-size: 12px;
  line-height: 42px;
  color: #cccccc;
`

export const SmallImageView = styled(Image)`
  width: 100px;
  margin: 0 0 5px 0;
  justify-content: flex-start;
`

export const SmallImageViewGrey = styled(Image)`
  width: 100px;
  margin: 0 0 5px 0;
  justify-content: flex-start;
  opacity: 0.6;
`
export const MainImageViewMobile = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
`

export const MobileWrapper = styled.div`
  margin: 30px 0 0 0;
`

export const ImgProductPageMobile = styled(Image)`
  width: 100%;
  max-height: 100%;
  margin: 0 0 0 0;
  z-index: -1;
`

export const ProductTitlePriceMobile = styled.div`
  grid-column-start: 2;
  grid-column-end: 2;
  font-size: 20px;
  word-wrap: break-word;
  font-family: 'LTUnivers-BasicRegular';
  font-size: 12px;
  line-height: 42px;
  text-align: right;
`

export const ProductTitleMobile = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
  font-size: 20px;
  word-wrap: break-word;
  font-family: 'LTUnivers-BasicRegular';
  font-size: 12px;
  line-height: 42px;
`
export const ProductDescriptionMobile = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  font-size: 20px;
  margin-bottom: 40px;
  word-wrap: break-word;
  font-family: 'LTUnivers-BasicRegular';
  font-size: 12px;
  line-height: 21px;
`

export const QuestionMobile = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  font-size: 12px;
  margin-bottom: 15px;
  word-wrap: break-word;
  font-family: 'LTUnivers-BasicRegular';
  font-size: 12px;
  line-height: 42px;
  color: #cccccc;
`

export const QuestionText = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  font-size: 20px;
  margin-bottom: 15px;
  word-wrap: break-word;
  font-family: 'LTUnivers-BasicRegular';
  font-size: 12px;
  line-height: 21px;
`

import React, { useState, useEffect, useContext, useRef } from 'react'
import { graphql, Link } from 'gatsby'
import 'pure-react-carousel/dist/react-carousel.es.css'
import SEO from '~/components/seo'
import ProductForm from '~/components/ProductForm'
import { ImgProductPageMobile } from '~/utils/styles'
import {
  OptionImages,
  SmallImageView,
  ImgProductPage,
  MainImageView,
  DescriptionWrapper,
  ProductTitle,
  ProductTitleMobile,
  ProductDescription,
  ProductTitlePrice,
  MainImageViewMobile,
  ProductTitlePriceMobile,
  ProductDescriptionMobile,
  ProductServices,
  QuestionMobile,
  QuestionText,
  MaintImageContainer,
} from './styles'
import { GeneralGrid, GeneralGridMobileProduct } from '~/utils/styles'
import { BrowserView, MobileView } from 'react-device-detect'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import NotFoundPage from '../../pages/404.js'
import Navigation from '../../components/Navigation'
import StoreContext from '~/context/StoreContext'
import Div100vh from 'react-div-100vh'

const ProductPage = ({ data }) => {
  const product = data.shopifyProduct
  const {
    store: { checkout, subscription },
  } = useContext(StoreContext)
  const slideRef = useRef()

  const [shippingReturns, setShippingReturns] = useState(false)
  const [photo, setPhoto] = useState('0')
  const [email, setEmail] = useState('')
  const [optionImage, setOptionImage] = useState(0)
  const [subscriptionMessage, setSubscriptionMessage] = useState(false)
  const [subscriptionErrorMessage, setSubscriptionErrorMessage] = useState(
    false
  )
  const [css, setCss] = useState('scale-in-center-center ')

  useEffect(() => {
    if (photo === '1') {
      const timer = setTimeout(() => setPhoto('2'), 3000)
      return () => clearTimeout(timer)
    } else if (photo === '2') {
      const timer = setTimeout(() => setPhoto('3'), 3000)
      return () => clearTimeout(timer)
    } else if (photo === '3') {
      const timer = setTimeout(() => setPhoto('1'), 3000)
      return () => clearTimeout(timer)
    }
  }, [photo])

  useEffect(() => {
    window.addEventListener('scroll', scrollSmoth)
  }, [])

  const scrollSmoth = ref => {
    var scrollAmount = 0
    var slideTimer = setInterval(function() {
      ref.scrollRight += 20
      scrollAmount += 20
      ref.scrollLeft -= 20
      scrollAmount += 20
      if (scrollAmount >= 100) {
        window.clearInterval(slideTimer)
      } else if (scrollAmount <= 100) {
        window.clearInterval(slideTimer)
      }
    }, 40)
  }

  const getPrice = price =>
    Intl.NumberFormat(undefined, {
      minimumFractionDigits: 2,
    }).format(parseFloat(price ? price : 0))

  return (
    <div className={css}>
      <SEO
        title={product.title + '| Bureau de Stil | BY BEN TAVERNITI '}
        description={product.description}
        keywords={[
          `bureau de Stil`,
          `ben tavertini`,
          `kim kardashian`,
          'LA Fashion',
          'fashion',
          'joyce bonelli',
          'unravel project',
          'trend brand',
          'online shop',
        ]}
      />

      <BrowserView style={{ height: '100vh' }}>
        <GeneralGrid>
          <Navigation />
          {/* <OptionImages>
            {product.images.map((image, index) => {
              return (
                <SmallImageView
                  fluid={image.localFile.childImageSharp.fluid}
                  key={image.id}
                  alt={product.title}
                />
              )
            })}
          </OptionImages> */}
          <MaintImageContainer
            style={{
              height: subscription ? '80vh' : null,
              filter: subscription ? 'opacity(0.2)' : null,
            }}
          >
            <MainImageView>
              {product.images.map((image, index) => {
                return (
                  <ImgProductPage
                    fluid={image.localFile.childImageSharp.fluid}
                    key={image.id}
                    alt={product.title}
                    id={index}
                  />
                )
              })}
            </MainImageView>
          </MaintImageContainer>

          <DescriptionWrapper
            style={{
              filter: subscription ? 'opacity(0.2)' : null,
            }}
          >
            <ProductTitle>{product.title}</ProductTitle>
            <ProductTitlePrice>
              USD${getPrice(product.priceRange.minVariantPrice.amount)}
            </ProductTitlePrice>
            <ProductDescription
              dangerouslySetInnerHTML={{ __html: product.descriptionHtml }}
            />{' '}
            <ProductForm product={product} />
          </DescriptionWrapper>
        </GeneralGrid>
      </BrowserView>

      <MobileView>
        <Navigation style={{ transform: 'translate3d(0, 0, 0)' }} />
        <Div100vh>
          <div
            style={{
              overflowY: 'scroll',
              webkitOverflowScrolling: 'touch',
            }}
          >
            <MainImageViewMobile
              onScroll={() => scrollSmoth(useRef)}
              useRef={slideRef}
            >
              {product.images.map((image, index) => {
                return (
                  <ImgProductPageMobile
                    fluid={image.localFile.childImageSharp.fluid}
                    key={image.id}
                    alt={product.title}
                    id={index}
                  />
                )
              })}
            </MainImageViewMobile>

            <GeneralGridMobileProduct>
              <ProductTitleMobile>{product.title}</ProductTitleMobile>

              <ProductTitlePriceMobile>
                USD${getPrice(product.priceRange.minVariantPrice.amount)}
              </ProductTitlePriceMobile>

              <ProductDescriptionMobile>
                <ProductForm product={product} />
              </ProductDescriptionMobile>

              <ProductDescriptionMobile
                dangerouslySetInnerHTML={{ __html: product.descriptionHtml }}
              />
            </GeneralGridMobileProduct>
          </div>
        </Div100vh>
      </MobileView>
    </div>
  )
}

export const query = graphql`
  query($handle: String!) {
    shopifyProduct(handle: { eq: $handle }) {
      id
      title
      handle
      productType
      description
      descriptionHtml
      shopifyId
      options {
        id
        name
        values
      }
      variants {
        id
        title
        price
        availableForSale
        shopifyId
        selectedOptions {
          name
          value
        }
      }
      priceRange {
        minVariantPrice {
          amount
          currencyCode
        }
        maxVariantPrice {
          amount
          currencyCode
        }
      }
      images {
        originalSrc
        id
        localFile {
          childImageSharp {
            fluid(maxWidth: 910) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default ProductPage
